 <template>
  <div>
    <v-tabs v-model="tab">
      <v-tab> Estatísticas </v-tab>
      <v-tab> Notificações </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card>
          <v-card-text>
            <WhatsappInstanceStats />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <WhatsappNotifications />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
 
 <script>
import WhatsappNotifications from "@/components/admin/views/WhatsappNotifications.vue";
import WhatsappInstanceStats from "@/components/admin/views/WhatsappInstanceStats.vue";
export default {
  components: { WhatsappNotifications, WhatsappInstanceStats },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
 
 <style>
</style>