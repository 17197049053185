<template>
  <div>
    <v-row>
      <v-col class="text-end">
        <v-btn @click="handleMigrateUsers()" color="primary">
          Migrar Usuários
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="align-center">
      <v-col cols="12" md="4">
        <app-select
          v-model="searchParams.type"
          label="Tipo"
          :items="types"
          clearable
        />
      </v-col>
      <v-col cols="12" md="4">
        <app-select
          :items="whatsapp_instances"
          v-model="searchParams.whatsapp_instance_id"
          item-text="name"
          clearable
          item-value="id"
          label="Instância WhatsApp"
        />
      </v-col>
      <v-col cols="12" md="2">
        <app-text-field v-model="searchParams.company_id" label="company_id" />
      </v-col>
      <v-btn class="ml-2" @click="select()" large color="primary">
        <app-icon>search</app-icon>
      </v-btn>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            <app-title>Empresas por Instância</app-title>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="[
                { align: 'center', text: 'ID', value: 'id' },
                { align: 'center', text: 'Nome', value: 'name' },
                { align: 'center', text: 'start_id', value: 'start_id' },
                { align: 'center', text: 'end_id', value: 'end_id' },
                { align: 'center', text: 'Quantidade', value: 'total' },
              ]"
              :items="stats.companiesPerInstance"
              disable-sort
              dense
              small
              hide-default-footer
              :items-per-page="-1"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>
            <app-title>Estatísticas de Envio</app-title>
          </v-card-title>
          <apexchart
            height="350"
            type="bar"
            :options="options"
            :series="series"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <app-title>Estatísticas de Envio</app-title>
          </v-card-title>
          <apexchart
            height="350"
            type="bar"
            :options="optionsStats"
            :series="seriesStats"
          />
        </v-card>
      </v-col>
    </v-row>
    <MigrateUsersWhatsappInstance
      @start="select()"
      ref="MigrateUsersWhatsappInstance"
    />
  </div>
</template>
<script>
import MigrateUsersWhatsappInstance from "@/components/admin/forms/MigrateUsersWhatsappInstance.vue";
export default {
  components: {
    MigrateUsersWhatsappInstance,
  },
  data() {
    return {
      stats: {
        lastNotifications: [],
        lastSuccessNotifications: [],
        lastErrorNotifications: [],
        statusPerDay: [],
      },

      whatsapp_instances: [],

      whatsAppNotifications: {},

      searchParams: {
        text: null,
        whatsapp_status: null,
        type_search: "DATE",

        start_date: new Date().toISOString().slice(0, 16),

        end_date: new Date().toISOString().slice(0, 16),
      },

      types: [
        { value: "welcome", text: "Boas vindas" },
        { value: "professional_day_schedule", text: "Agenda diária" },
        { value: "password_recovery", text: "Recuperação de senha" },

        { value: "event_reminder", text: "Lembrete de evento" },
        { value: "session_reminder", text: "Lembrete de sessão" },
        {
          value: "session_scheduling_request",
          text: "Solicitação de remarcação de sessão",
        },
        { value: "session_video_call_link", text: "Link de video chamada" },

        { value: "booking_created", text: "auto-agendamento criado" },
        { value: "booking_reminder", text: "Lembrete de auto-agendamento" },

        { value: "automatic_billing", text: "Cobrança automática" },
        { value: "manual_billing", text: "Cobrança manual" },
      ],
    };
  },
  mounted() {
    // this.select();
    // this.getWhatsappApiInstances();
  },

  computed: {
    options() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
        },
        colors: ["#ff0000", "#0EBC00"],
        xaxis: {
          categories: this.categories,
        },
      };
    },

    series() {
      return [
        {
          name: "Error",
          data: this.getNotificationsTotals("lastErrorNotifications"),
        },

        {
          name: "Enviadas com Sucesso",
          data: this.getNotificationsTotals("lastSuccessNotifications"),
        },
      ];
    },
    categories() {
      return this.stats.lastNotifications.map((item) =>
        this.$format.dateBr(item.date)
      );
    },

    optionsStats() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
        },
        colors: ["#9E9E9E", "#ff0000", "#9E9E9E", "#0091EA", "#0EBC00"],
        xaxis: {
          categories: this.categories,
        },
      };
    },

    seriesStats() {
      return [
        {
          name: "willNotify",
          data: this.getNotificationsByStatusTotals("willNotify"),
        },
        { name: "error", data: this.getNotificationsByStatusTotals("error") },
        {
          name: "sending",
          data: this.getNotificationsByStatusTotals("sending"),
        },
        {
          name: "pending",
          data: this.getNotificationsByStatusTotals("pending"),
        },
        {
          name: "success",
          data: this.getNotificationsByStatusTotals("success"),
        },
      ];
    },
    categoriesStats() {
      return this.stats.statusPerDay.map((item) =>
        this.$format.dateBr(item.date)
      );
    },
  },

  methods: {
    select(page) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("admin/whatsapp-stats", this.searchParams)
        .then((response) => {
          this.$loading.finish();
          this.stats = response;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    getNotificationsDates(stat) {
      return this.stats[stat].map((item) => item.date);
    },
    getNotificationsTotals(stat) {
      return this.stats[stat].map((item) => item.total);
    },
    getNotificationsByStatusTotals(stat) {
      return this.stats.statusPerDay
        .filter((item) => item.status == stat)
        .map((item) => item.total);
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },
    getWhatsappApiInstances() {
      this.$http
        .index("admin/whatsapp-instances")
        .then((response) => {
          this.whatsapp_instances = response.whatsapp_instances;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleMigrateUsers() {
      this.$refs.MigrateUsersWhatsappInstance.open();
    },
  },
};
</script>

<style></style>
